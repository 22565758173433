<template>
  <!-- Desc: Placement view at the campaign level of campaign details -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <div
      v-if="tabConfig.showSplitTab"
      class="u-display-flex u-flex-justify-content-flex-start u-spacing-m-l"
    >
      <splitTabs
        :active-tab="activeTab"
        :is-tabs-list="true"
        :tabs-list="tabConfig.tabsList"
        @tabClicked="onTabClick"
      />
    </div>
    <change-placement-status
      v-if="activeTab && activeTab === tabConfig.showSplitTab"
      :default-state="displayState"
      :title="tabConfig.title || 'Placement Status'"
      :is-loading="isLoading"
      class="u-spacing-p-l"
      @onSave="onSave"
    />
    <tableWrapper
      v-else
      :table-data-component-key="tableDataComponentKey"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const splitTabs = () => import('@/components/widgets/splitTabs.vue');
const changePlacementStatus = () =>
  import(
    '@/pages/entity_details/configs/walmart/components/changePlacementStatus.vue'
  );
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');

export default {
  components: {
    tableWrapper,
    splitTabs,
    changePlacementStatus
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      getState: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      widget: {},
      tabConfig: null,
      activeTab: '',
      displayState: {},
      isLoading: false
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      let headerOptions = [];
      if (this.tabMetadata?.widgets[0]) {
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            enableManageColumns: true,
            headerContainerGridStyles: {
              display: 'flex',
              'justify-content': 'flex-end'
            },
            headerOptions: headerOptions,
            rowHeight: 45
          }
        };
      }
      return obj;
    }
  },
  async created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': [],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.entityId}`
      },
      replaceEntityInDimensionNameValueList: {
        ':campaign_id': this.entityId
      }
    };
    this.activeTab = tabConfig?.activeTab;
    this.displayState = tabConfig?.displayState;
    Vue.component('iconTextActions', iconTextActions);
    await this.tabConfig?.init?.(this);
  },
  methods: {
    onTabClick(tab) {
      this.activeTab = tab;
    },
    fetchData(type) {
      let reqPayload;
      reqPayload = {
        retailer: 'walmart',
        campaignId: this.$route?.params?.entityId,
        advertiserId: this.$route?.query?.advertiser_id,
        adGroupId: this.$route?.params?.subEntityId,
        filterName: null,
        filterDate: null,
        type
      };

      return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', reqPayload);
    },
    async onSave(payload) {
      await this.tabConfig?.onSave?.(this, payload);
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
</style>
